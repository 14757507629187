<template>
  <dialog-outline persistent v-model="showDialog">
    {{ dialogTitle }}
    <template v-slot:icon-name>
      {{ dialogIcon }}
    </template>

    <!-- Close Dialog icon -->
    <template v-slot:icon-cross>
      <base-tooltip-button @click="closeDialog">
        <template v-slot:icon-name>
          {{ iconCloseDialog }}
        </template>
        <template v-slot:toolTip>
          {{ closeDialogTooltip }}
        </template>
      </base-tooltip-button>
    </template>

    <template v-slot:pre-context>
      <!-- display error -->
      <base-alert
        v-model="showAlert"
        :dismissible="isAlertDismissible"
        :type="currentAlertType"
      >
        <span v-html="alertMessage"></span>
      </base-alert>
    </template>

    <!--    &lt;!&ndash; display upload error&ndash;&gt;
    <base-alert v-model="hasUploadError" :dismissible="false">
      <span v-html="uploadError"></span>
    </base-alert>-->

    <template v-slot:context>
      <!-- record details banner, which represents a subject that current operation will be performed -->
      <record-banner :record="record"></record-banner>

      <!-- form -->
      <v-form>
        <v-container fluid>
          <!-- select Scanner -->
          <v-row dense>
            <v-col>
              <v-select
                :items="scannerNames"
                :value="selectedScanner"
                :label="labelSelectedScanner"
                :loading="isLoadingScanners"
              >
                <template v-slot:prepend>
                  <v-icon>{{ iconScanner }}</v-icon>
                </template>
              </v-select>
            </v-col>
          </v-row>

          <!-- Scanning Options -->
          <v-row>
            <v-col>
              <v-expansion-panels accordion multiple>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <span v-show="!anyScanningOption"
                      >Enhance image options</span
                    >
                    <core-chip-group
                      v-show="anyScanningOption"
                      :items="selectedEnhancementOptionLabels"
                      :column="true"
                      :chip-small="true"
                    >
                    </core-chip-group>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row dense>
                      <v-col
                        ><v-checkbox
                          dense
                          v-model="selectedEnhancementOptions"
                          :label="
                            imageEnhancementLabel(enhancementNames.autoRotate)
                          "
                          :value="enhancementNames.autoRotate"
                        ></v-checkbox
                      ></v-col>
                      <v-col
                        ><v-checkbox
                          dense
                          v-model="selectedEnhancementOptions"
                          :label="
                            imageEnhancementLabel(enhancementNames.deskew)
                          "
                          :value="enhancementNames.deskew"
                        ></v-checkbox
                      ></v-col>
                    </v-row>
                    <v-row dense>
                      <v-col
                        ><v-checkbox
                          dense
                          v-model="selectedEnhancementOptions"
                          :label="
                            imageEnhancementLabel(enhancementNames.despeckle)
                          "
                          :value="enhancementNames.despeckle"
                        ></v-checkbox
                      ></v-col>
                      <v-col
                        ><v-checkbox
                          dense
                          v-model="selectedEnhancementOptions"
                          :label="
                            imageEnhancementLabel(enhancementNames.autoCrop)
                          "
                          :value="enhancementNames.autoCrop"
                        ></v-checkbox
                      ></v-col>
                    </v-row>
                    <v-row dense>
                      <v-col
                        ><v-checkbox
                          dense
                          v-model="selectedEnhancementOptions"
                          :label="
                            imageEnhancementLabel(enhancementNames.holeFill)
                          "
                          :value="enhancementNames.holeFill"
                        ></v-checkbox
                      ></v-col>
                      <v-col
                        ><v-checkbox
                          dense
                          v-model="selectedEnhancementOptions"
                          :label="
                            imageEnhancementLabel(
                              enhancementNames.discardBlankPages
                            )
                          "
                          :value="enhancementNames.discardBlankPages"
                        ></v-checkbox
                      ></v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>

          <!-- acquired Image info -->
          <v-row v-show="acquiredImageCount > 0">
            <v-col>
              <span>{{ acquiredImageInfo }}</span>
            </v-col>
          </v-row>
          <radio-group
            v-show="isDocumentOpened && hasPages"
            @change="onCheckInOptionChange"
            :options="checkInFileOptions"
            :select-option="defaultCheckInFileOption"
          >
          </radio-group>
        </v-container>
      </v-form>
    </template>

    <!-- progress - uploading/check-in scanned image file -->
    <progress-display v-if="visibleUploadProgress">
      {{ uploadProgressInfo }}
    </progress-display>

    <template v-slot:actions>
      <base-tooltip-button @click="onOk()" :disabled="!enabledScanCommand">
        <template v-slot:default>
          {{ scanCommandName }}
        </template>
        <template v-slot:toolTip>
          {{ scanCommandTooltip }}
        </template>
      </base-tooltip-button>
    </template>

    <template v-slot:actionCancel>
      <base-button @click.stop="closeDialog()">
        <template v-slot:default>
          Cancel
        </template>
      </base-button>
    </template>
  </dialog-outline>
</template>

<script>
//mixin
import { dialogOutlineMixin } from "@/mixins/shared/base/dialog/dialogOutlineMixin";
import { baseComponentMixin } from "@/mixins/shared/base/component/baseComponentMixin";
import { recordBannerAble } from "@/mixins/shared/bannerAble/recordBannerAble";
import { wingScanMixin } from "@/mixins/scan/wingScanMixin";
import { fileCheckInMixin } from "@/mixins/shared/file/fileCheckInMixin";
import { progressAbleMixin } from "@/mixins/shared/progressAble/progressAbleMixin";
//model
import { findImageOperation, imageOperation } from "@/model/image/imageModel";
import { findImageEnhancement } from "@/model/acquire/scan/scanningOptions";
import { fullRecordName } from "@/services/record/recordService";

export default {
  name: "ScanDialog",
  components: {
    CoreChipGroup: () =>
      import("@/components/shared/core/chipGroup/CoreChipGroup")
  },
  mixins: [
    dialogOutlineMixin,
    baseComponentMixin,
    recordBannerAble,
    wingScanMixin,
    fileCheckInMixin,
    progressAbleMixin
  ],
  props: {
    /**
     * @type {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, fieldValues: {id: number, name:string, fieldDataType: number, fieldDataTypeName: string, value: string}[], flyingFields: {id:number, sequence:number}[], operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]} | undefined | null}
     */
    selectedRecord: undefined,
    scanEvent: {
      type: Function,
      required: true
    },
    scanCompleteEvent: {
      type: Function,
      required: true
    },
    hasPages: {
      type: Boolean,
      default: true
    },
    isDocumentOpened: {
      type: Boolean,
      default: false
    },
    isDocumentViewer: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      //save latest user selected file option
      currentSelectedOption: undefined
    };
  },
  computed: {
    isViewer() {
      return this.isDocumentViewer;
    },
    record() {
      return this.selectedRecord ?? undefined;
    },
    currentRecord() {
      return this.selectedRecord ?? undefined;
    },
    /**
     * scan Operation
     * @return {{name: string, icon: string, label: string}} scan Operation
     */
    operation() {
      return findImageOperation(imageOperation.Scan);
    },

    /**
     * scan operation Label
     * @return {string} scan operation Label
     */
    operationLabel() {
      return this.operation?.label;
    },

    /**
     * dialog Icon
     * @return {string} Icon name
     */
    dialogIcon() {
      return this.operation?.icon;
    },

    /**
     * Get scan Page Dialog title
     * @return {string} scan Page Dialog title
     */
    dialogTitle() {
      return `${this.operationLabel} Page`;
    },

    /**
     * dialog Ok Command Label
     * remarks: overwrite modalDialogMixin.dialogOkCommandLabel
     * @return {string} OK command Label
     */
    dialogOkCommandLabel() {
      return this.operationLabel;
    },
    /**
     * visible Upload Progress
     * @return {Boolean}
     */
    visibleUploadProgress() {
      return this.isUploadingPages;
    },
    message() {
      return "Scan Page Successful";
    },
    /**
     * Dialog action
     * @return {string}
     */
    action() {
      return `${this.operationLabel} ${
        this.record?.recordType
      }: '${fullRecordName(this.record)}'`;
    }
  },
  methods: {
    /**
     * Handle on Dialog Opened event
     */
    async onDialogOpened() {
      await this.initializeWebScanning();
      this.internalAlert = undefined;
      //sets default file option to previously selected option
      this.defaultCheckInFileOption =
        this.currentSelectedOption ?? this.checkInFileOption;
    },

    /**
     * Handle on Dialog Closed event
     */
    onDialogClosed() {
      try {
        this.currentSelectedOption = this.checkInFileOption;
        this.internalAlert = undefined;
        this.resetScanVariables();
        // Aborts the current background operation in progress
        //this.abortScan();
        // shutdown all Web Capture services
        this.disposeScanning();
      } catch {
        this.showSnackbarError(`Failed to dispose Scanning`, true);
      }
    },

    /**
     * get image EnhancementLabel
     * @param {string} name Image Enhancement name
     * @return {string} Image Enhancement Label
     */
    imageEnhancementLabel(name) {
      return findImageEnhancement(name)?.label;
    },

    onOk() {
      this.scanEvent(this.isInsertFile, this.isInsertFileAtBeginning);
    },

    /**
     * overwrite basicWingScan afterUploadCompleted
     * clean scanning variables and reload updated document
     * remarks: this method will be called by uploadCompletedHandler
     * @param success
     */ async afterUploadCompleted(success) {
      try {
        if (success) {
          this.currentSelectedOption = this.checkInFileOption;
          await this.autoCloseDialog(this.message);
          this.scanCompleteEvent();
        }
      } catch (e) {
        this.internalAlert = this.createAlertError(
          this.formatAlertError(this.action, e),
          false
        );
        this.setUploadError(e);
      } finally {
        this.isUploadingPages = false;
      }
    }
  }
};
</script>

<style scoped></style>
